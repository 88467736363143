// extracted by mini-css-extract-plugin
export var appDesktop = "app-desktop";
export var appMobile = "app-mobile";
export var closed = "closed-ekxft";
export var cluster = "cluster-vyX3b";
export var clusterDead = "cluster-dead-bc04k";
export var clusters = "clusters-szrTv";
export var connecting = "connecting-ovM6T";
export var dead = "dead-ouo9s";
export var footer = "footer-lnn8d";
export var identifying = "identifying-D4rOW";
export var open = "open-jxxZb";
export var page = "page-ytNb8";
export var ready = "ready-CB6ww";
export var resuming = "resuming-lX1ir";
export var row = "row-tFFZQ";
export var rowBody = "row-body-MajS4";
export var rowBodyColumn = "row-body-column-6Yn_E";
export var rowBodyIcon = "row-body-icon-P3h7Y";
export var rowBodyText = "row-body-text-DRNbE";
export var rowBodyTextRight = "row-body-text-right-o1Yqx";
export var rowTitle = "row-title-GDh8o";
export var shard = "shard-NZY95";
export var shards = "shards-kXqns";