// extracted by mini-css-extract-plugin
export var active = "active-kYJIO";
export var card = "card-PODPO";
export var cardDescription = "card-description-DWzrb";
export var cardInner = "card-inner-uhGzJ";
export var cardTitle = "card-title-akXOx";
export var cardToggle = "card-toggle-ssJDS";
export var cards = "cards-P1ziW";
export var container = "container-EfFNj";
export var divider = "divider-TLs12";
export var header = "header-yiBRR";
export var headerTitle = "header-title-_J8vh";
export var page = "page-NTRC_";