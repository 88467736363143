// extracted by mini-css-extract-plugin
export var active = "active active-kdMWb";
export var btn = "btn-BNi1N";
export var container = "container-iFcqK";
export var discord = "discord-wnyCO";
export var footer = "footer-bD4oB";
export var github = "github-a11pv";
export var header = "header-LHw_X";
export var headerDescription = "header-description-VukY3";
export var headerFooter = "header-footer-mIpiz";
export var headerIcons = "header-icons-E1QcB";
export var headerTitle = "header-title-LNEXp";
export var left = "left-NyZRt";
export var noselect = "noselect-kVjzd";
export var right = "right-Zp4sQ";
export var section = "section-JH3Sj";
export var sectionColumn = "section-column-imWTn";
export var sectionTitle = "section-title-fDRzd";
export var sections = "sections-h895B";