// extracted by mini-css-extract-plugin
export var appMobile = "app-mobile";
export var container = "container-wk24x";
export var content = "content-DNoqU";
export var header = "header-TgzOe";
export var headerDescription = "header-description-i3SsO";
export var headerTitle = "header-title-mYZI_";
export var page = "page-t9QOo";
export var section = "section-BJgc6";
export var sectionDescription = "section-description-aq3h9";
export var sectionTitle = "section-title-pjI8T";