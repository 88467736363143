import * as React from 'react';

import { NavPage } from '../NavPage';


export class ErrorPage extends React.PureComponent {
  render() {
    return (
      <NavPage>
        <h2>404 lol</h2>
      </NavPage>
    );
  }
}
