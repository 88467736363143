// extracted by mini-css-extract-plugin
export var active = "active-dgtVt";
export var appDesktop = "app-desktop";
export var appMobile = "app-mobile";
export var column = "column-pYNb3";
export var columnBig = "column-big-IVAIn";
export var command = "command-w__jl";
export var commandCategory = "command-category-NTALE";
export var commandDescription = "command-description-Ps5t4";
export var commandFooter = "command-footer-fCVPq";
export var commandHeader = "command-header-tc0ag";
export var commandName = "command-name-iTWOJ";
export var commandText = "command-text-RWITE";
export var commandUsage = "command-usage-DMElJ";
export var commands = "commands-gLP1F";
export var container = "container-HcVL9";
export var divider = "divider-an3R2";
export var header = "header-zU26B";
export var opened = "opened-A4gIh";
export var page = "page-wyXmY";
export var search = "search-SN3ib";
export var sidenav = "sidenav-l0zTB";
export var sidenavSpacer = "sidenav-spacer-UXvPZ";
export var sidenavTitle = "sidenav-title-XUmro";
export var table = "table-U6M4Z";
export var tableHeader = "table-header-s0OOh";
export var tables = "tables-O6qcf";