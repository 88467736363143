// extracted by mini-css-extract-plugin
export var active = "active active-Eo45q";
export var appDesktop = "app-desktop";
export var appMobile = "app-mobile";
export var brand = "brand-DF9k6";
export var brandLogo = "brand-logo-Ei2pF";
export var brandText = "brand-text-CshF0";
export var btn = "btn-SrDC6";
export var left = "left-lqWte";
export var nav = "nav-Bg_oy";
export var navbar = "navbar-IYouC";
export var noselect = "noselect-DEtxs";
export var right = "right-WbvTv";
export var sidenav = "sidenav-hQdmy";
export var sidenavOverlay = "sidenav-overlay-J7n0c";
export var trigger = "trigger-srsFe";